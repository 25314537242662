import useQuery from "effects/useQuery";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import Authentication from "services/Authentication";
import deleteMachineById from "services/deleteMachineById";
import getMachines from "services/getMachines";
import getOrganizationShopFloorData from "services/getOrganizationShopFloorData";
import postOrganizationShopFloorData from "services/postOrganizationShopFloorData";
import putOrganizationShopFloorData from "services/putOrganizationShopFloorData";
import notify from "utils/toastMsg";
import AddNewMachine from "./components/AddNewMachine";
import DeleteMachine from "./components/DeleteMachine";
import MachineToolCard from "./components/MachineToolCard";
import ToolingDatabase from "./components/ToolingDatabase";
import ToolpathOptimisation from "./components/ToolpathOptimisation";
import "./style.css";

const options = {
  menu: {
    "": "Menu",
    ToolingDatabase: "Tooling Database",
    ToolpathOptimisation: "Toolpath Optimisation",
    SpindleHealthMonitoring: "Spindle Health Monitoring",
  },
  configure: {
    "": "Configure",
    MachineTool: "Machine Tool Identity",
    Controller: "Controller Settings",
    Spindle: "Spindle Settings",
    Edit: "Edit Machine",
    Delete: "Delete Machine Tool",
  },
};

function PredictiveMaintenance() {
  let auth = new Authentication();
  const jwtPayload = auth.getAccessTokenPayload();
  const { organizationId } = jwtPayload;
  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenuValue, setSelectedMenuValue] = useState("");
  const [showComponent, setShowComponent] = useState(false);
  const {
    data: [shopFloorData] = [],
    error,
    isLoading,
    refresh: refreshShopFloor,
  } = useQuery({
    queryFn: () =>
      Promise.all([
        getOrganizationShopFloorData({ organizationId }).then(
          (res) => res.data
        ),
        getMachines().then((res) => res.data),
      ]),
    onSuccess: ({ data: [shopFloors, { machines }] }) => {
      if (!shopFloors.machineShopFloor?.length) {
        //if there is no shopFloors saved already,
        //divide the machines into three equal lengths and send as column 1, 2 and 3

        const colsToPost = machines.map((eachCol, colIndex) => ({
          machineId: eachCol.machineId,
          columnNum: "column1",
          cardRowNumber: colIndex + 1,
        }));
        handlePostShopFloorCardPositioningData({
          organizationId,
          columns: colsToPost,
        });
      } else {
        const column1Items = machines
          .filter(
            (machine) =>
              !!shopFloors.machineShopFloor.find(
                (mShopFloor) =>
                  mShopFloor.machineId === machine.machineId &&
                  mShopFloor.columnNum === "column1"
              )
          )
          .sort((a, b) => rowSort(a, b, shopFloors.machineShopFloor));

        const column2Items = machines
          .filter(
            (machine) =>
              !!shopFloors.machineShopFloor.find(
                (mShopFloor) =>
                  mShopFloor.machineId === machine.machineId &&
                  mShopFloor.columnNum === "column2"
              )
          )
          .sort((a, b) => rowSort(a, b, shopFloors.machineShopFloor));
        const column3Items = machines
          .filter(
            (machine) =>
              !!shopFloors.machineShopFloor.find(
                (mShopFloor) =>
                  mShopFloor.machineId === machine.machineId &&
                  mShopFloor.columnNum === "column3"
              )
          )
          .sort((a, b) => rowSort(a, b, shopFloors.machineShopFloor));

        setColumns({
          column1: {
            name: "",
            items: column1Items.map((eachMachine) => ({
              ...eachMachine,
              content: (
                <MachineToolCard
                  key={eachMachine.machineId}
                  machine={eachMachine}
                  setSelectedMenuValue={setSelectedMenuValue}
                  setShowComponent={setShowComponent}
                  handleMachineToolDelete={handleMachineToolDelete}
                  setActiveMachineId={setActiveMachineId}
                  options={options}
                  fetchAllMachines={refreshShopFloor}
                />
              ),
            })),
          },
          column2: {
            name: "",
            items: column2Items.map((eachMachine) => ({
              ...eachMachine,
              content: (
                <MachineToolCard
                  key={eachMachine.machineId}
                  machine={eachMachine}
                  setSelectedMenuValue={setSelectedMenuValue}
                  setShowComponent={setShowComponent}
                  handleMachineToolDelete={handleMachineToolDelete}
                  setActiveMachineId={setActiveMachineId}
                  options={options}
                  fetchAllMachines={refreshShopFloor}
                />
              ),
            })),
          },
          column3: {
            name: "",
            items: column3Items.map((eachMachine) => ({
              ...eachMachine,
              content: (
                <MachineToolCard
                  key={eachMachine.machineId}
                  machine={eachMachine}
                  setSelectedMenuValue={setSelectedMenuValue}
                  setShowComponent={setShowComponent}
                  handleMachineToolDelete={handleMachineToolDelete}
                  setActiveMachineId={setActiveMachineId}
                  options={options}
                  fetchAllMachines={refreshShopFloor}
                />
              ),
            })),
          },
        });
      }
    },
  });

  const [columns, setColumns] = useState({
    column1: { name: "", items: [] },
    column2: { name: "", items: [] },
    column3: { name: "", items: [] },
  });

  const handleClose = () => {
    setShowMenu(false);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }

    // Create a newColumns object based on the current state of columns
    const newColumns = { ...columns };
    const sourceColumn = newColumns[source.droppableId];
    const destColumn = newColumns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];

    if (source.droppableId === destination.droppableId) {
      const [removed] = sourceItems.splice(source.index, 1);
      sourceItems.splice(destination.index, 0, removed);
      newColumns[source.droppableId] = {
        ...sourceColumn,
        items: sourceItems,
      };
    } else {
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      newColumns[source.droppableId] = {
        ...sourceColumn,
        items: sourceItems,
      };
      newColumns[destination.droppableId] = {
        ...destColumn,
        items: destItems,
      };
    }

    setColumns(newColumns);
    handlePutShopFloorCardPositioningData({
      organizationId,
      columns: newColumns,
    });
    // fetchShopFloorDataResponse();
  };

  const [activeMachineId, setActiveMachineId] = useState(null);

  const renderSelectedComponent = () => {
    switch (selectedMenuValue) {
      case "ToolingDatabase":
        return <ToolingDatabase />;
      case "ToolpathOptimisation":
        return <ToolpathOptimisation />;
      case "MachineTool":
        return <MachineToolCard />;
      case "Delete":
        return (
          <DeleteMachine
            handleMachineToolDelete={handleMachineToolDelete}
            setShowComponent={setShowComponent}
          />
        );
      default:
        return null;
    }
  };

  const handleMachineToolDelete = async () => {
    try {
      await deleteMachineById({ machineId: activeMachineId });
      notify("Machine Tool deleted successfully. ✅ ");
      handleClose();
      refreshShopFloor();
      setSelectedMenuValue("close-modal");
      setShowMenu(false);
    } catch (err) {
      throw err;
    }
  };

  const handlePostShopFloorCardPositioningData = async ({
    organizationId,
    columns,
  }) => {
    try {
      await postOrganizationShopFloorData({ organizationId, meta: columns });
      refreshShopFloor();
      notify("Shop floor updated successfully. ✅ ");
    } catch (err) {
      notify("An error occurred while updating the shop floor. ❌");
      throw err;
    }
  };

  const handlePutShopFloorCardPositioningData = async ({
    organizationId,
    columns,
  }) => {
    try {
      const colsToUpdate = Object.entries(columns).reduce((acc, [key, val]) => {
        acc.push(
          ...val.items.map((item, index) => ({
            columnNum: key,
            cardRowNumber: index + 1,
            machineShopFloorId: shopFloorData?.machineShopFloor?.find(
              (xx) => xx.machineId === item.machineId
            )?.machineShopFloorId,
          }))
        );

        return acc;
      }, []);
      await putOrganizationShopFloorData({
        organizationId,
        columns: colsToUpdate,
      });
      notify("Shop floor updated successfully. ✅ ");
    } catch (err) {
      notify("An error occurred while updating the shop floor. ❌");
      throw err;
    }
  };

  if (isLoading) {
    return (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (error) {
    return <div>Oops! Something went wrong. Please try again.</div>;
  }

  return (
    <div className="home-predictive-maintenance">
      <div className="header-border-predictive-maintenance d-flex flex-row justify-content-between align-items-center">
        <div></div>
        <h1 className="heading" style={{ marginLeft: "11rem" }}>
          Shop Floor
        </h1>
        <div className="d-flex flex-direction row">
          <Link
            className="btn btn-primary d-flex flex-column align-items-center justify-content-center rounded mr-3"
            style={{ fontSize: "15px" }}
            to="/"
          >
            Back to Dashboard
          </Link>
          <button
            className="btn btn-primary"
            onClick={() =>
              handlePostShopFloorCardPositioningData({
                organizationId,
                columns,
              })
            }
          >
            Save
          </button>
        </div>
      </div>
      <div className="home-predictive-maintenance">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="grid-container">
            {Object.entries(columns).map(([columnId, column]) => {
              return (
                <Droppable key={columnId} droppableId={columnId}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="column"
                    >
                      <h2>{column.name}</h2>
                      {column.items.map((item, index) => (
                        <Draggable
                          key={item.machineId}
                          draggableId={item.machineId + ""}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="card"
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      {/* Additional component at the end of each column */}
                      <div
                        className="d-flex flex-column justify-content-center align-items-center add-new-machine-predictive-maintenance"
                        onClick={() => setShowMenu(ColumnsObj[columnId])}
                      >
                        <h1 style={{ marginTop: "0em" }}>+</h1>
                        <h5 style={{ marginTop: "-1em" }}>Add New Machine</h5>
                      </div>
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
        </DragDropContext>
      </div>
      {showMenu && (
        <AddNewMachine
          handleClose={handleClose}
          fetchAllMachines={refreshShopFloor}
          columnNum={showMenu}
        />
      )}
      {showComponent && (
        <div className="component-popup">{renderSelectedComponent()}</div>
      )}
    </div>
  );
}

const rowSort = (a, b, machineShopFloor) => {
  const found1 = machineShopFloor.find(
    (mShopFloor) => mShopFloor.machineId === a.machineId
  );
  const found2 = machineShopFloor.find(
    (mShopFloor) => mShopFloor.machineId === b.machineId
  );
  if (found1 && found2) {
    if (found2.cardRowNumber) {
      return 0;
    }
    return found1.cardRowNumber - found2.cardRowNumber;
  }
  return 0;
};

export default PredictiveMaintenance;
const ColumnsObj = {
  column1: 1,
  column2: 2,
  column3: 3,
};
