import { XestTable } from "components/CustomComponents";
import React from "react";
import getNotifymeUsers from "services/getNotifymeUsers";

const NotifyMeTable = (props) => {
  const {
    setShowDeleteDialog,
    setDeleteFunctionToUse,
    setIdToDelete,
    refreshKey,
  } = props;

  const columns = [
    {
      dataIndex: "first_name",
      title: "First Name",
      filterType: {
        dbCol: "notifyme_users.first_name",
        type: "string",
      },
      width: 30,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "last_name",
      title: "Last Name",
      filterType: {
        dbCol: "notifyme_users.last_name",
        type: "string",
      },
      width: 30,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "email",
      title: "Email",
      filterType: {
        dbCol: "notifyme_users.email",
        type: "string",
      },
      width: 30,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "phone_number",
      title: "Email",
      filterType: {
        dbCol: "notifyme_users.phone_number",
        type: "string",
      },
      width: 30,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "Actions",
      fixed: "right",
      width: 15,
      render(text, record) {
        return (
          <button
            className="btn btn-danger btn-sm"
            style={{ width: "7em", cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              setShowDeleteDialog(true);
              setDeleteFunctionToUse("dashboard");
              setIdToDelete(record.notifyme_user_id);
            }}
          >
            Delete
            <i className="ion ion-md-trash ml-1" />
          </button>
        );
      },
    },
  ];
  const params = {
    apiCallFn: async (queryParams) => {
      let data, error;
      await getNotifymeUsers({ queryParams })
        .then(async (res) => {
          data = res.data;
        })
        .catch((err) => (error = err));

      return {
        data,
        error,
      };
    },
    deps: [1],
    initialPageSize: 5,
    initialFilters: [],
    initialSortCriteria: null,
    config: {
      filtersKeyGen() {
        return "dt--notifyme-users";
      },
      tableNum: 2,
    },
  };
  return <XestTable key={refreshKey} columns={columns} params={params} />;
};

export default NotifyMeTable;
