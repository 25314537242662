import { useEffect, useState } from "react";

function useQuery({ queryFn, onError, onSuccess, enabled = true }) {
  const [queryState, setqueryState] = useState({
    isLoading: enabled,
    error: null,
    data: undefined,
  });

  const refetch = async () => {
    const newQueryState = { ...queryState };
    newQueryState.error = null;
    try {
      const data = await queryFn();
      newQueryState.data = data;
      if (onSuccess) onSuccess({ data });
    } catch (err) {
      newQueryState.error = err;
      if (onError) onError({ error: err });
    }
    newQueryState.isLoading = false;
    setqueryState(newQueryState);
  };

  useEffect(() => {
    if (queryState.isLoading) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState.isLoading]);

  const refresh = () => {
    setqueryState((prev) => ({ ...prev, isLoading: true }));
  };

  return { refresh, ...queryState };
}

export default useQuery;
