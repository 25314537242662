import http from "utils/http";
import { shopFloor } from "constants/endpoints";

const putOrganizationShopFloorData = ({ organizationId, columns }) => {
  const ENDPOINT = shopFloor.putOrganizationShopFloorData({
    organizationId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.put(`${API_BASE_URL}${ENDPOINT}`, {
    data: columns,
  });
};

export default putOrganizationShopFloorData;

// setColumns(newColumns);
// handlePutShopFloorCardPositioningData({
//   organizationId,
//   shopFloorDataId: shopFloorData?.shopFloorDataId,
//   columns: newColumns,
// });
