import http from "utils/http";
import { shopFloor } from "constants/endpoints";

const postOrganizationShopFloorData = ({ organizationId, meta }) => {
  const ENDPOINT = shopFloor.postOrganizationShopFloorData({ organizationId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: meta,
  });
};

export default postOrganizationShopFloorData;
